import React, { Component } from 'react';
import "css-reset-and-normalize";
import { createGlobalStyle } from "styled-components";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactResizeDetector from 'react-resize-detector';

import Menu from './Menu.js';
import ListPage from './ListPage.js';
import HomePage from './HomePage.js';
import EditPage from './EditPage.js';
import ScanPage from './ScanPage.js';
import StaticPage from './StaticPage.js';
import InfoBar from './InfoBar.js';
import Circles from './Circles.js';

import axios from 'axios';
import { apiUrl, capitalize, t } from '../helpers'

import { isLargeScreen } from '../helpers/'
import styled from 'styled-components'
const uuidv1 = require('uuid/v1');

class BaseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examples: [],
      menuOpen: false,
      currentPage: "home",
      mcmmId: null,
      navStack: [],
      userFilter: [],
      musicianFilter: null,
      yearFilter: null,
      showInfoWindow: null,
      translations: [],
      locale: "de",
      projectionId: null,
      zoomLevel: 0,
      updateHash: uuidv1()
    }

    this.setUserFilter = this.setUserFilter.bind(this);
    this.setMusicianFilter = this.setMusicianFilter.bind(this);
    this.setYearFilter = this.setYearFilter.bind(this);
    this.setLocale = this.setLocale.bind(this);
    this.pollFilter = this.pollFilter.bind(this);
    this.handleFilterClose = this.handleFilterClose.bind(this);
    this.setInfoWindow = this.setInfoWindow.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.setZoomLevel = this.setZoomLevel.bind(this);
    this.formatMatch = this.formatMatch.bind(this);
    this.formatMatchData = this.formatMatchData.bind(this);
    this.setNumBios = this.setNumBios.bind(this);
    this.refreshUpdateHash = this.refreshUpdateHash.bind(this);
  }

  onResize = (width, height) => {
    this.setState({
      largeScreen: isLargeScreen(width, height),
    })
  }  

  refreshUpdateHash = () => {
    this.setState({updateHash: uuidv1()})
  }

  setLocale = (l)=> {
    this.setState({locale: l})
    localStorage.setItem('mcmm_locale', l);    
  }

  setZoomLevel = (z)=> {
    this.setState({zoomLevel: z})
  }

  setUserFilter = async (filter) => {
    console.log("setUserFilter", filter);
    this.setState({
      userFilter: filter,
      musicianFilter: null,
      yearFilter: null,
      showInfoWindow: null,
      matchData: null
    }, async ()=>{
      if(filter.length == 2) {
        let matchData = await axios.get(apiUrl + "/user_match/", {params: {userIds: JSON.stringify(filter)}});
        console.log(matchData);
        if(matchData && matchData.data && matchData.data.status == "ok") {
          this.setState({matchData: matchData.data.match})
        }
      }

      if(this.state.currentPage != "home") {
        this.navigate("home");  
      }
    });
  }

  setMusicianFilter(musician) {
    console.log("setMusicianFilter", musician);
    this.setState({
      musicianFilter: musician,
      userFilter: [],
      yearFilter: null,
      showInfoWindow: null,
      matchData: null
    })
  }

  setNumBios(num) {
    this.setState({numBios: num});
  }
  
  setYearFilter(year) {
    this.setState({
      musicianFilter: null,
      userFilter: [],
      yearFilter: year,
      showInfoWindow: null,
      matchData: null
    })
  }

  setInfoWindow(entries) {
    this.setState({showInfoWindow: entries});
  }

  pollFilter(id) {
    axios.get(apiUrl + "/filter?username=" + id)
    .then((response)=> {
      //console.log(response);
      if(response.data && response.data.docs && response.data.docs.length) {
        let ids = this.state.projectionId ? [] : [id];
        response.data.docs[0].filter.forEach(filterId=>{ids.push(filterId)});
        if(JSON.stringify(ids) !== JSON.stringify(this.state.userFilter))
          this.setUserFilter(ids);  
      }
    })
    .catch((e)=> {
      console.log(e);
    });
  }

  handleFilterClose() {

    console.log("handleFilterClose");

    axios.get(apiUrl + "/remove_filter/" + (this.state.projectionId ? this.state.projectionId : this.state.mcmmId))
    .then((response)=> {
      console.log(response);
    })
    .catch((e)=> {
      console.log(e);
    });

    this.setUserFilter([]);
    this.setMusicianFilter(null);
    this.setYearFilter(null);
  }


  async componentDidMount() {

    console.log("BaseContainer componentDidMount");

    let response = await axios.get(apiUrl + "/translation");
    console.log(response);
    this.setState({translations: response.data.docs});

    let projection_id = null;
    if(typeof URLSearchParams !== "undefined") {
      let queryParams = new URLSearchParams(window.location.search); 
      projection_id = queryParams.get("projection_id");
    }

    console.log("window.location.href", window.location.href);
    
    if(projection_id) {

      console.log(projection_id);

      this.setState({
        projectionId: projection_id
      });

      // start polling for filter
      this.pollInterval = setInterval(()=>{this.pollFilter(projection_id)}, 5000);
      
    } else {

      let hash = null; // window.location.hash;

      let split = window.location.href.split("#");
      console.log("split", split);
      if(split.length == 2) {
        hash = split[1];
      }

      let mcmmId = localStorage.getItem('mcmmId');  
      if(hash) {
        mcmmId = hash;
        this.setState({
          currentPage: "list",
          mcmmId: mcmmId,
          menuOpen: true,
          navStack: ["home"]
        })
      }
      if(!mcmmId) {
        mcmmId = uuidv1();
      }
      this.setState({mcmmId: mcmmId});  
      localStorage.setItem('mcmmId', mcmmId);    

      let baseLocale = localStorage.getItem('mcmm_locale');
      if(baseLocale) {
        this.setLocale(baseLocale);
      }   
  
    }

    //disableBodyScroll(document.querySelector('body'))
    this.loadedTimestamp = Date.now();

    this.stillInterval = setInterval(()=>{
      let now = Date.now();
      if(this.state.projectionId && (now - this.loadedTimestamp > 10 * 60 * 1000)) {
         window.location.reload(); 
      }
      if(this.moved) {
        this.moved = false; // reset        
      } else {
        console.log("still");
        this.setState({still: true});
      }
    }, 30000);

  }

  onMouseMove() {
    if(!this.moved) {
      console.log("moved");
      this.setState({still: false});
    }
    this.moved = true;
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
    clearInterval(this.stillInterval);
  }

  toggleMenu = ()=>{
    if (this.state.menuOpen) {
      if(this.state.currentPage == "edit") {
        this.back();
        return;
      }

      this.navigate("home")
    }
    this.setState({menuOpen: !this.state.menuOpen})
  }

  navigate = (page, entry, noSave=false)=>{
    if(!noSave) {
      let navStack = this.state.navStack;
      navStack.push(this.state.currentPage);
      //console.log(navStack);
      this.setState({navStack});
    }
    this.setState({
      menuOpen: page !== "home",
      currentPage: page,
      currentEntry: entry,
      pageKey: entry,
      showInfoWindow: null
    });
    if(page != "list") {
      let pathName = window.location.pathname + (this.state.projectionId ? "?projection_id=" + this.state.projectionId : "")
      window.history.pushState("", document.title, pathName);
    }
  }

  reset = () => {
    this.setUserFilter([]);
    this.navigate("home");
  }

  back = () => {
    if(this.state.currentPage == "scan") {
      this.navigate("home");
      return;
    }

    let navStack = this.state.navStack;
    let previousPage = navStack.pop();
    this.setState({navStack});
    this.navigate(previousPage, this.state.entry, true);
  }

  formatMatch(key, number) {
    return t(this.state.translations, key, this.state.locale) + ": " + Math.floor(number * 100) + "%";
  }

  formatMatchData(matchData) {

    let cities = "";
    if(matchData.cities.length) {
      cities += "(";
      matchData.cities.forEach((c, index)=>{
        cities += capitalize(c);
        if(index < matchData.cities.length - 1) cities += ", ";
      });
      cities +=")";
    }

    let musicians = "";
    if(matchData.musicians.length) {
      musicians += "(";
      matchData.musicians.forEach((m, index)=>{
        musicians += capitalize(m);
        if(index < matchData.musicians.length - 1) musicians += ", ";
      });
      musicians += ")";
    }

    let years = "";
    if(matchData.lowestMatchYear && matchData.highestMatchYear) {
      years = "(" + matchData.lowestMatchYear + " - " + matchData.highestMatchYear + ")";
    }

    return <div>
      <MatchComponent>{this.formatMatch("city_match", matchData.cityMatch)} {cities}</MatchComponent>
      <MatchComponent>{this.formatMatch("year_match", matchData.yearMatch)} {years}</MatchComponent>
      <MatchComponent>{this.formatMatch("musician_match", matchData.musicianMatch)} {musicians}</MatchComponent>
    </div>
  }


  render() {
    const showFilterBar = (this.state.userFilter.length > 0 || this.state.musicianFilter || this.state.yearFilter)

    const pages = {
      "home": <HomePage mcmmId={this.state.mcmmId} 
        userFilter={this.state.userFilter} 
        setUserFilter={this.setUserFilter}
        musicianFilter={this.state.musicianFilter}
        setMusicianFilter={this.setMusicianFilter}
        yearFilter={this.state.yearFilter}
        setYearFilter={this.setYearFilter}
        largeScreen={this.state.largeScreen}
        translations={this.state.translations}
        locale={this.state.locale}
        setInfoWindow={this.setInfoWindow}
        showInfoWindow={this.state.showInfoWindow}
        projectionMode={this.state.projectionId ? true : false}
        still={this.state.still}
        handleFilterClose={this.handleFilterClose}
        setZoomLevel={this.setZoomLevel}
        matchData={this.state.matchData}
        setNumBios={this.setNumBios}
        updateHash={this.state.updateHash}
        />,
      "list": <ListPage 
        mcmmId={this.state.mcmmId} editEntry={(entry)=>{this.navigate("edit", entry)}}
        translations={this.state.translations}
        locale={this.state.locale}
        setUserFilter={this.setUserFilter}
        titleKey="my_entries"
        navigate={this.navigate}
        />,
      "edit": <EditPage 
        mcmmId={this.state.mcmmId} back={this.back} 
        entry={this.state.currentEntry}
        translations={this.state.translations}
        locale={this.state.locale}
        titleKey={this.state.currentEntry ? "update entry" : "new entry"}
        refreshUpdateHash={this.refreshUpdateHash}
        />,
      "scan": <ScanPage 
        mcmmId={this.state.mcmmId} setUserFilter={this.setUserFilter}
        translations={this.state.translations}
        locale={this.state.locale}
        pollFilter={this.pollFilter}
        titleKey="scanner"
      />,
      "page": <StaticPage
        translations={this.state.translations}
        locale={this.state.locale}
        pageKey={this.state.pageKey}
        titleKey={this.state.pageKey + "_heading"}
      />  
    }
    let mainContent = pages["home"];

    const page = this.state.currentPage !== "home" ? pages[this.state.currentPage] : null
    
    return (
      [
        <GlobalStyles key="globalstyles" />,
        <ReactResizeDetector key="resize" handleWidth handleHeight onResize={this.onResize} />,
        <Menu 
          menuOpen={this.state.menuOpen}
          key="menu" 
          close={this.toggleMenu} 
          navigate={this.navigate}
          translations={this.state.translations}
          locale={this.state.locale}
          setLocale={this.setLocale}
          reset={this.reset}
          projectionMode={this.state.projectionId ? true : false}
          render={page}
          onMouseMove={this.onMouseMove}
        />, 
        <MainContent key="main" onMouseMove={this.onMouseMove}>
          {this.state.zoomLevel < 6 && <Circles/>}
          
          {!showFilterBar && 
            <MenuBar>
              <MenuButton onClick={this.toggleMenu} src="images/menu.png"/>
              <MenuText>MATCH CUT</MenuText>
            </MenuBar>
          }
          
          {showFilterBar && 
            <InfoBar onClose={this.handleFilterClose} menuOpen={this.state.menuOpen}>
              {(this.state.userFilter.length > 0) && 
                <span>{t(this.state.translations, "filter_for", this.state.locale)} 
                &nbsp;{this.state.userFilter.length}&nbsp;
                {this.state.userFilter.length > 1 ? t(this.state.translations, "users", this.state.locale) : t(this.state.translations, "user", this.state.locale)}
                {this.state.matchData && this.formatMatchData(this.state.matchData)}
              </span>}
              {(this.state.musicianFilter) && <span>{t(this.state.translations, "filter_for", this.state.locale)} {this.state.numBios} {t(this.state.translations, "users_that_named", this.state.locale)} {capitalize(this.state.musicianFilter)}</span>}
              {(this.state.yearFilter) && <span>{t(this.state.translations, "filter_for", this.state.locale)} {t(this.state.translations, "entries_from_year", this.state.locale)} {this.state.yearFilter}</span>}
            </InfoBar>
          }

          {mainContent}
          {(this.state.currentPage === "home" && !showFilterBar && !this.state.projectionId || this.state.currentPage == "list") && 
            <AddButton onClick={()=>this.navigate("edit")} largeScreen={this.state.largeScreen}>
              +
            </AddButton>
          }
        </MainContent>
      ]
    );
  }
}

export default BaseContainer;

const MenuBar = styled.div`
  position: fixed;
  top:0;
  z-index: 100;
  display: flex;
  padding: 1rem;
`

const MenuText = styled.span`
  font-size: 2.5rem;
  padding-left: 1rem;
  letter-spacing: 0.2ex;
`

const MenuButton = styled.img`
  width: auto;
  height: calc(2 * 1.25rem); // scaling factor for the png image * font-size
  &:hover {cursor: pointer}; 
`

const MainContent = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
`

const AddButton = styled.div`
  position: fixed;
  z-index: 100;
  right: 1rem;
  bottom: ${ props => (props.largeScreen ? "9rem" : "2rem") };
  width: 3rem;
  height: 3rem;
  font-size: 4rem;
  line-height: 1.2rem;
  font-family: NeutraTextLight;
  text-align: center;
  border: 2px solid black;
  border-radius: 50%;
  background-color: white;
  padding: 10px;
  &:hover {cursor: pointer}; 
`

const MatchComponent = styled.span`
  display: block;
`

const GlobalStyles = createGlobalStyle`
`