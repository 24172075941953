import React, { Component } from 'react';
import styled from 'styled-components';

class Circles extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    let circles = [];
    let step = 6;
    for(let i = 0; i < 300; i ++) {
      circles.push(
        <circle cx="66%" cy="-100" r={i * step} stroke="#ddd" strokeWidth="1" fill="none" />
      )
    }

    for(let i = 0; i < 300; i ++) {
      circles.push(
        <circle cx="10%" cy="130%" r={i * step * 1.1} stroke="#ddd" strokeWidth="1" fill="none" />
      )
    }
    

    /*return (
      <svg style={{position: "absolute"}} height="100%" width="100%">
        {circles}
      </svg> 
    )*/

    return (
      <Background/>
    )
  }
}

const Background = styled.div`
  position: absolute;
 width: 100%;
 height: 100%;
 background-image: url("/images/moiree.png");
 background-size: cover;
 background-position: ${ props => (props.largeScreen ? "0 0" : "20% -20%") };
`



export default Circles;
